import { render } from "./FAQItem.vue?vue&type=template&id=56927bd8&scoped=true"
import script from "./FAQItem.vue?vue&type=script&lang=js"
export * from "./FAQItem.vue?vue&type=script&lang=js"

const cssModules = script.__cssModules = {}
import style0 from "./FAQItem.vue?vue&type=style&index=0&id=56927bd8&module=true&lang=css"
cssModules["$style"] = style0
import "./FAQItem.vue?vue&type=style&index=1&id=56927bd8&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-56927bd8"

export default script