<template>
  <div :class="$style.section">
    <h3 :class="$style.header">{{ title }}</h3>
    <span v-if="subtitle" :class="$style.subheader">{{ subtitle }}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      isRequired: true,
    },

    subtitle: {
      type: String,
      default: null
    }
  }
}
</script>

<style module>
  .section {
    margin-bottom: 14px;
  }

  .header {
    margin: 0;
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 7px;
  }

  .subheader {
    display: block;
    font-size: 13px;
    line-height: 20px;
    color: #444444;
    margin-bottom: 7px;
  }
</style>