<template>
  <SubpageLayout :to="{ name: 'home' }" :title="$t('returns.header')">
    <BlockButton
      as="a"
      href="/rma-request"
      :title="$t('returns.registerButton.title')"
      :subtitle="$t('returns.registerButton.subtitle')"
      :logo="logo"
    >
      <template #icon="{ className }">
        <ExternalLinkIcon :class="className" />
      </template>
    </BlockButton>

    <SectionSeparator />

    <SectionHeader :title="$t('returns.faq.header')" :subtitle="$t('returns.faq.subheader')" />

    <FAQ :items="faq"></FAQ>

    <HelpCenterButton />
  </SubpageLayout>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import SubpageLayout from '@/components/SubpageLayout'
import BlockButton from '@/components/BlockButton'
import SectionSeparator from '@/components/SectionSeparator'
import SectionHeader from '@/components/SectionHeader'
import FAQ from '@/components/FAQ'
import ExternalLinkIcon from '@/assets/icons/external-link.svg'
import HelpCenterButton from '@/components/HelpCenterButton'
import logo from '@/assets/miinto-small@2x.png'

export default {
  components: {
    BlockButton,
    ExternalLinkIcon,
    FAQ,
    SectionSeparator,
    SectionHeader,
    SubpageLayout,
    HelpCenterButton,
  },
  setup() {
    const store = useStore()

    return {
      faq: computed(() => store.state.returnFAQ),
      logo,
    }
  }
}
</script>