<template>
  <div>
    <InitAnimation>
    <div :class="$style.header">
      <router-link :to="to" custom v-slot="{ href, navigate }">
        <a :href="href" :class="$style.goBack" @click="navigate">
          <NextIcon :class="$style.backIcon" />
          {{ title }}
        </a>
      </router-link>
    </div>
    </InitAnimation>
    <InitAnimation>
      <div :class="$style.content">
        <slot></slot>
      </div>
    </InitAnimation>
  </div>
</template>

<script>
import InitAnimation from '@/components/InitAnimation'
import NextIcon from '@/assets/icons/next.svg'

export default {
  props: {
    title: {
      type: String,
      isRequired: true,
    },
    to: {
      type: [String, Object],
      isRequired: true,
    },
  },

  components: {
    InitAnimation,
    NextIcon,
  }
}
</script>

<style module>

.goBack {
  position: relative;
  background: #fff; 
  -webkit-appearance: none;
  border: 0;
  padding: 0;
  outline: 0;
  display: block;
  width: 100%;
  padding: 25px 50px;
  line-height: 25px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: inherit;
  text-decoration: none;
}

.backIcon {
  transform: rotate(180deg) translateY(50%);
  position: absolute;
  left: 30px;
  top: 50%;
  width: 23px;
  height: 17px;
}

.content {
  padding: 30px;
}
</style>