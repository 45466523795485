<template>
  <ul :class="$style.list">
    <li v-for="(item, key) in items" :key="key">
      <FAQItem :question="item.question" :answer="item.answer" :is-expanded="key === expandedKey" @toggle="toggle(key)" />
    </li>
  </ul>
</template>

<script>
import { ref } from 'vue'
import FAQItem from './FAQItem'

export default {
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },

  components: {
    FAQItem,
  },

  setup() {
    const expandedKey = ref(null)

    const toggle = (key) => {
      expandedKey.value = expandedKey.value === key ? null : key
    }


    return {
      toggle,
      expandedKey
    }
  }
}
</script>

<style module>
  .list {
    list-style: none;
    padding: 0;
    margin: 0 0 20px 0;
  }
</style>
