<template>
  <div :class="$style.item"></div>
</template>

<style module>
  .item {
    height: 1px;
    background: #f2f2f2;
    margin: 29px 0;
    width: 100%;
  }
</style>