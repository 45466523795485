<template>
  <BlockButton
    as="a"
    href="/contact"
    :title="$t('component.helpCenterButton.title')"
    :subtitle="$t('component.helpCenterButton.subtitle')"
    type="light"
  >
    <template #icon="{ className }">
      <ExternalLinkIcon :class="className" />
    </template>
  </BlockButton>
</template>

<script>
import BlockButton from '@/components/BlockButton'
import ExternalLinkIcon from '@/assets/icons/external-link.svg'

export default {
  components: {
    BlockButton,
    ExternalLinkIcon,
  },
}
</script>