<template>
  <div :class="[$style.item, {[$style['is-expanded']]: isExpanded }]" @click="$emit('toggle')">
    <div :class="$style.head">
      <h4 :class="$style.question">{{ question }}</h4>
      <button :class="$style.toggler" @click.prevent.stop="$emit('toggle')">
        <ExpandIcon :class="$style.icon" />
      </button>
    </div>
    <transition name="slide">
      <div v-show="isExpanded" :class="$style.answer" v-html="answer"></div>
    </transition>
  </div>
</template>

<script>
import ExpandIcon from '@/assets/icons/expand.svg'

export default {
  props: {
    question: {
      type: String,
      isRequired: true,
    },
    answer: {
      type: String,
      isRequired: true,
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ExpandIcon,
  },
}
</script>

<style module>
  .item {
    display: flex;
    flex-direction: column;
    color: inherit;
    text-decoration: none;
    padding: 20px;
    background: #fff;
    border: 1px solid #f2f2f2;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    margin-bottom: 12px
  }

  .item.is-expanded .icon {
    transform: rotate(-180deg);
  }

  .head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .toggler {
    background: none;
    border: none;
    outline: none;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
    border-radius: 8px;
    border: solid 1px #f8f8f8;
    background-color: #fbfcfe;
    flex-shrink: 0
  }

  .icon {
    transform: rotate(0deg);
    transition: transform .1s;
    width: 16px;
  }

  .question {
    margin: 0;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
  }

  .answer {
    margin-top: 8px;
    font-size: 13px;
    line-height: 20px;
  }
</style>

<style scoped>
  .slide-enter-active {
    transform-origin: left top;
    animation: slide-in .2s;
  }
  .slide-leave-active {
    transform-origin: left top;
    animation: slide-in .2s reverse;
  }
  @keyframes slide-in {
    0% {
      /* transform: opacity(0); */
      /* max-height: 0; */
      transform: scaleY(0);
      max-height: 0;
      opacity: 0;
    }
    50% {
      /* transform: scale(1.5); */
      opacity: 0;
    }
    100% {
      max-height: 200px;
      /* transform: opacity; */
      transform: scaleY(1);
      opacity: 1;
    }
  }
</style>